@tailwind base;
@tailwind components;
@tailwind utilities;

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}
iframe {
  pointer-events: auto;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.coursecontent ul,
.pastpaperquestion ul,
.bookcontent ul,
.testcontent ul {
  list-style-type: disc;
  list-style-position: inside;
}

.coursecontent ol,
.pastpaperquestion ol,
.bookcontent ol,
.testcontent ol {
  list-style-type: decimal;
  list-style-position: inside;
}

.coursecontent table td,
.pastpaperquestion table td,
.bookcontent table td,
.testcontent table td {
  border-width: 1px;
  border-color: rgb(156 163 175);
}
.coursecontent table,
.pastpaperquestion table,
.bookcontent table,
.testcontent table {
  border-collapse: collapse;
  border-width: 1px;
  border-color: rgb(156 163 175);
}

.forumcontent iframe {
  width: 100%;
  /* min-height: 150px; */
  height: 200px;
}

.bg-carousel {
  background-image: url("/src/images/bgggg.png");
}
.bg-valueproposition {
  background-image: url("/src/images/valueprop.svg");
}
.bg-mascot {
  background-image: url("/src/images/mascot.png");
}
.bg-faq {
  background-image: url("/src/images/faq.svg");
}
.bg-features {
  background-image: url("/src/images/features.png");
}
.bg-whoisitfor {
  background-image: url("/src/images/whoisitfor.png");
}
.bg-pricing {
  background-image: url("/src/images/pricing.png");
}
.bg-signup {
  background-image: url("/src/images/signup.png");
}
