@tailwind base;
@tailwind components;
@tailwind utilities;
@tailwindcss /aspect-ratio @import url('https://fonts.googleapis.com/css?family=Poppins');

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.h-24px {
  height: 24px;
}

.h-36px {
  height: 36px;
}

.h-48px {
  height: 48px;
}

.h-64px {
  height: 64px;
}

.h-72px {
  height: 72px;
}

.h-96px {
  height: 96px;
}

.h-124px {
  height: 124px;
}

.w-24px {
  width: 24px;
}

.w-36px {
  width: 36px;
}

.w-48px {
  width: 48px;
}

.w-64px {
  width: 64px;
}

.w-72px {
  width: 72px;
}

.w-96px {
  width: 96px;
}

.w-124px {
  width: 124px;
}

.oneSwiper .swiper-button-prev,
.oneSwiper .swiper-button-next {
  color: white !important;
  font-weight: 600;
}

.oneSwiper .swiper-pagination-bullet-active {
  color: white !important;
  background-color: white !important;
}

.twoSwiper .swiper-button-prev,
.twoSwiper .swiper-button-next {
  color: white !important;
  background-color: #e8ad26 !important;
  border-radius: 25%;
  font-weight: 600;
}

.twoSwiper .swiper-pagination-bullet-active {
  color: white !important;
  background-color: #e8ad26 !important;
  border-radius: 25%;
}

.twoSwiper .swiper-button-prev:after,
.twoSwiper .swiper-rtl .swiper-button-next:after,
.twoSwiper .swiper-button-next:after,
.twoSwiper .swiper-rtl .swiper-button-prev:after {
  font-size: smaller !important;
}

.threeSwiper .swiper-button-prev,
.threeSwiper .swiper-button-next {
  color: #183f71 !important;
  background-color: #f0f0f0 !important;
  font-weight: 600;
  border-radius: 25%;
}

.threeSwiper .swiper-pagination-bullet-active {
  color: #183f71 !important;
  background-color: #f0f0f0 !important;
  border-radius: 25%;
}

.threeSwiper .swiper-button-prev:after,
.threeSwiper .swiper-rtl .swiper-button-next:after,
.threeSwiper .swiper-button-next:after,
.threeSwiper .swiper-rtl .swiper-button-prev:after {
  font-size: 1rem !important;
  line-height: 1.5rem !important;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.scrollbar-hidden::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge add Firefox */
.scrollbar-hidden {
  -ms-overflow-style: none;
  scrollbar-width: none;
  /* Firefox */
}

.PhoneInputInput {
  outline: none;
  border: none;
}

.PhoneInputInput:focus {
  border: none;
  border-color: transparent;
  outline: 0;
  outline-color: transparent;
  --tw-ring-color: transparent;
}

.PhoneInput {
  width: 100%;
  outline: none;
  border: solid 1px #183f71;
  border-radius: 0.25rem;
  padding: 0 0.5rem;
}

.react-calendar__navigation {
  width: 100%;
}

.react-calendar__viewContainer {
  width: 100%;
}

.calendar-footer {
  width: 90%;
  display: flex;
  justify-items: space-between;
}

iframe {
  pointer-events: auto;
}

.hoverHyperLinks:hover {
  color: red;
  text-decoration: underline;
  text-decoration-color: red;
  text-decoration-thickness: 3px;
}

.pastpaperquestion img {
  display: inline;
}

.questionoftheday img {
  display: inline;
}
.forumcontent img {
  display: inline-block;
  margin-left: 4px;
  margin-right: 4px;
}
.coursecontent table td,
.testcontent table td {
  border-width: 1px;
  border-color: rgb(156 163 175);
}
.coursecontent table,
.testcontent table {
  border-collapse: collapse;
  border-width: 1px;
  border-color: rgb(156 163 175);
}
.coursecontent img,
.testcontent img {
  display: inline;
  margin-top: 5px;
}
.coursecontent ul {
  list-style-type: disc;
  list-style-position: inside;
}

.coursecontent ol {
  list-style-type: decimal;
  list-style-position: inside;
}

.forumcontent iframe {
  width: 100%;
  height: 200px;
  /* height: auto; */
}

@layer base {
  h1 {
    @apply text-2xl;
  }
  h2 {
    @apply text-xl;
  }
}
